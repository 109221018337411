.retool {
  height: 80%;
  .retool-header {
    color: rgb(64,0,149);
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .retool-iframe-cell {
    height: 800px;
    .retool-iframe {
      width: 100%; 
      height: 100%;
      border: 1px solid darkgray;
      .retool-viz {
        display: none;
      }
    }
  }
  .retool-cell {
    padding-top: 10px;
    .retool-link {
      text-decoration:none;
      color: rgb(64,0,149);
    }
    .retool-link:hover {
      color:rgb(178,178,178);
    }
  }
  width: 80%;
}