.home {
    .home-tile {
        padding-top: 40px;
        text-align: center;
        align-items: center;
    }
    .icons {
        color: rgb(64,0,149);
        width: 150px;
        height: 150px;
    }
    .icon-tile {
        padding-top: 120px;
        text-align: center;
        align-items: center;
    }
    .tile:hover {
        background-color: #f0f0f0;
        border-radius: 25px;
    }
    .tile-image {
        width: 150px;
        mix-blend-mode: multiply;
    }
    .smaller-tile-image {
        padding-top: 10px;
        width: 130px;
        mix-blend-mode: multiply;
    }
    .smaller-tile-text {
        padding-top: 7px;
    }
    .home-link {
        text-decoration: none;
        color: black;
    }
    .davd-tile {
        padding-top: 25px;
        width: 150px;
        mix-blend-mode: multiply;
    }
    .davd-tile-text {
        padding-top: 13px;
    }
    .home-headers {
        padding-top: 40px;
    }
    .info-tile {
        padding-top: 20px;
        width: 130px;
        mix-blend-mode: multiply;
    }
    .info-text {
        padding-top: 16px;
    }
}