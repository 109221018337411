
header a.logo {
  display: inline-block;
}

header a.logo img {
  vertical-align: middle;
  padding-right: 6px;
}

header h6.logo-header{
  flex:1;
  color: white;
  display: inline-block;
  vertical-align: middle;
  padding-left: 10px;
}

.logo-header{
  flex:1;
  display: inline-block;
  vertical-align: middle;
  padding-left: 10px;
}

.user-name-section {
  display: inline-block;
  padding-left: 16px;
  color: white;
}

.user-name-section span {
  vertical-align: middle;
}

.active-route {
  color: white !important;
}

.inactive-route {
  color: #bbdaf5 !important;
}

.section-header {
  color: white !important;
  height: 64px;
  background:  black;
  padding-left: 2px;
}

.tab {
  font-family: "Mark Pro";
  height: 100%;
  text-decoration: none;
  .tab-indicator {
    background: rgb(102,206,219);
    height: 3px;
  }
}
.tab:hover {
  text-decoration: none;
}

.menu {
  background-color: black;
  width: 160px;
  .menu-item {
    color: white; 
    height: 50px;
  }
  .link {
    text-decoration: none;
  }
  
  .link:hover {
    text-decoration: none;
  }
}

