.uploadData {
  width: 80%
}


.refresh {
  background-color: transparent;
  .refresh-section {
    background-color: transparent;
    .refresh-button {
      padding-top: 30px;
      max-width: 30px;
      min-width: 30px;
      background-color: transparent;
      color: grey;
    }
    .info-button {
      padding-top: 30px;
      max-width: 30px;
      min-width: 30px;
      background-color: transparent;
      color: grey;
    }
  }
}

.upload-button-div {
  padding-top: 56px;
  padding-bottom: 56px;
  .file-browser {
    display: none;
  }
  .upload-button {
    color: rgb(64,0,149);
    border-color: rgb(64,0,149);
  }
}

.uploadData-header {
  color: rgb(64,0,149);
  padding-top: 20px;
  padding-bottom: 20px;
}

.menu-selected {
  color: rgb(64, 0, 149);
}

.upload-cp-div {
  color:  rgb(64,0,149);
  text-align: center;
  align-items: center;
  .circular-progress {
    color:  rgb(64,0,149);
  }
}

.dialog-div-verify {
  padding-left: 62px;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 366px;
  text-align: center;
  align-items: center;
  .circular-progress {
    color:  rgb(64,0,149);
  }
}

.dialog {
  max-height: 500px;
  .dialog-content {
    width: 500px;
    // max-height: 700px;
  }
  .upload-message {
    padding-left: 60px;
    padding-right: 30px;
  }
  .upload-message-verify {
    padding-left: 60px;
    padding-right: 30px;
    padding-bottom: 16px;
  }
  .dialog-title {
    text-align: center;
    align-items: center;
  }
  .dialog-button {
    color: rgb(64,0,149);
    background: transparent;
  }
  .success-icon-div {
    text-align: center;
    align-items: center;
    color: #439400;
    padding-bottom: 30px;
    padding-top: 30px;
  }
  .success-icon {
    transform: scale(3);
  }
  .cancel-icon-div {
    text-align: center;
    align-items: center;
    color: red;
    padding-bottom: 16px;
  }
  .cancel-icon {
    transform: scale(2);
    padding-top: 4px;
    padding-left: 4px;
  }
  .error-icon-div {
    text-align: center;
    align-items: center;
    color: red;
    padding-bottom: 16px;
  }
  .error-icon {
    transform: scale(2);
  }
  .dialog-row {     
    color: #400095;
    .dialog-row-10 {
      width: 10%;
      border-bottom: none;
      color: #400095;
      padding-top: 8px;
      padding-bottom: 8px;
      .file-icon {
        transform: scale(1.2);
      }
      .error-icon {
        transform: scale(1.2);
        color: red;
        padding-left: 22px;
        padding-top: 8px;
      }
      .warning-icon {
        transform: scale(1.2);
        color: #FFCC00;
        padding-left: 22px;
        padding-top: 8px;
      }
      .cancel-button {
        background: transparent;
        width: 46px;
        min-width: 46px;
        padding-left: 26px;     
        .cancel-icon {
          transform: scale(1.2);
          color: red;
        }
      }
      .cloud-icon-div {
        position: relative;
        display: flex;
        padding-left: 12px;
        padding-bottom: 6px;
      }
      .cloud-icon {
        color: #5283b2;
        padding-left: 10px;
        padding-top: 8px;
        transform: scale(1.2);
      }
      .cloud-done-icon {
        padding-left: 22px;
        padding-top: 8px;
        color: #5283b2;
        transform: scale(1.2);
      }
      .cloud-circular-progress {
        position: absolute;
        color: #5283b2;
      }
    }
    .dialog-row-80{
      padding-top: 8px;
      width: 80%;
      max-width: 334px;
      border-bottom: none;
    }
    .dialog-div {
      text-align: center;
      align-items: center;
      .barcolor {
        background-color: #400095;
        height: 5px;
      }
      .color {
        background-color: #dcccf0;
        height: 5px;
      }
    }
    .dialog-file-div {
      padding-bottom: 6px;
      color: black;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  }

.info-dialog {
  max-height: 800px;
  .dialog-title {
    text-align: center;
    align-items: center;
  }
  .dialog-button {
    color: rgb(64,0,149);
    background: transparent;
  }
  .info-dialog-content {
    // width: 600px;
    max-width: 600px;
    padding-left: 48px;
    padding-right: 48px;
    .dialog-table {
      max-width: 500px;
      .info-header {
        background: rgb(64,0,149);   
        .info-header-20 {
          width: 20%;
          color: white;
          // align-items: center;
        }
        .info-header-center-20 {
          width: 20%;
          color: white;
          text-align: center;
        }
        .info-header-60 {
          width: 60%;
          color: white;
        }
      }
      .info-row {
        color: black;
      }
      .info-icon-row {
        color: black;
        align-items: center;
        text-align: center;
        .info-smaller-cell {
          padding-left: 28px;
          // text-align: center;
          // border-bottom: none;
        }
        // .info-smaller-cell {
        //   padding-left: 8px;
        // }
        .error-icon {
          transform: scale(1.2);
          color: red;
          // padding-left: 22px;
          padding-top: 8px;
        }
        .warning-icon {
          transform: scale(1.2);
          color: #FFCC00;
          // padding-left: 22px;
          padding-top: 8px;
        }
        .cloud-icon-div {
          position: relative;
          display: flex;
          // padding-left: 12px;
          text-align: center;
          // align-items: center;
          padding-bottom: 6px;
        }
        .cloud-icon {
          color: #5283b2;
          padding-left: 9px;
          padding-top: 8px;
          transform: scale(1.2);
        }
        .cloud-done-icon {
          padding-left: 9px;
          padding-top: 8px;
          color: #5283b2;
          transform: scale(1.2);
          text-align: center;
        }
        .cloud-circular-progress {
          position: absolute;
          color: #5283b2;
          // text-align: center;
        }
        .success-icon {
          transform: scale(1.2);
          color: #439400;
        }
      }
    }
  } 
}

.history {
  .recent-header {
    color: rgb(64,0,149);
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .upload-cp-div {
    color:  rgb(64,0,149);
    align-items: center;
    text-align: center;
    .circular-progress {
      color:  rgb(64,0,149);
    }
  }
}

.select-boxes {
  .select {
  }
  .select::before {
    border-color: black;
  }
  .select::after {
    border-color: rgb(64, 0, 149);
  }
}






